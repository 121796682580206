import React from "react";
import {GLSTYLE, GLTRAN,GLTF,GLCON} from "../GLBL";
import illu from "../img/contact-us-illustration.svg";
import blob2 from "../img/blob (3).svg";
import { makeStyles, useTheme } from '@material-ui/core/styles';  
import Grid from '@material-ui/core/Grid';
import {

  
  Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  heroDiv:{
    display:'flex', justifyContent:'space-between',
    alignItems:'center',padding:100,paddingBottom:50,
       [theme.breakpoints.down('sm')]: {
        padding:10,
        marginTop:100,
       
       },
      },

      planText:{
        height:"100%",width:'60%',
        [theme.breakpoints.down('sm')]: {
          padding:10,
         width:"100%"
         },
      },
 
}));    

export default function ContactUs(props) {
  const classes = useStyles();
  const theme = useTheme();

  // console.log(document.getElementById("aa").offsetTop);


   

      return (

        <div className={classes.heroDiv}>
    
    <Grid container spacing={0}>

<Grid  item sm={6} xs={12}>
<div style={{height:"100%",width:'100%',textAlign:localStorage.getItem("lang")=="AR"?"right":"left"}}>
    <div style={{color:GLSTYLE.colors.primary.s600,fontSize:20,fontWeight:'bold'}}>{props.title}</div>
    <div style={{color:GLSTYLE.colors.blue.s900,fontSize:40,fontWeight:'bold',marginTop:10}}>
    {GLTF(GLTRAN.contactUsText_AR,GLTRAN.contactUsText_EN)}
    </div>

    <div style={{color:GLSTYLE.colors.gray.s800,fontSize:20,marginTop:20}}>
    {GLTF(GLTRAN.khartoumOfficeContacts_AR,GLTRAN.khartoumOfficeContacts_EN)}
    </div>


    <div style={{color:GLSTYLE.colors.gray.s800,fontSize:20,marginTop:20}}>
    {GLTF(GLTRAN.gadarifOfficeContacts_AR,GLTRAN.gadarifOfficeContacts_EN)}
    </div>
    
{/*     
    <div style={{display:'flex',justifyContent:'right',alignItems:'center',marginTop:20}}>
    <div style={{color:'white',padding:10,borderRadius:5,backgroundColor:GLSTYLE.primary}}>
    {GLTF(GLTRAN.ShowMoreText_AR,GLTRAN.ShowMoreText_EN)}
    </div>
    </div>
     */}
    
    
    </div>
  </Grid>


  
<Grid  item sm={6} xs={12}>
<div style={{height:'100%',width:"100%",boxShadow:GLSTYLE.box.none,borderRadius:5}}>
    <img alt={""} style={{borderRadius:5}} src={illu} width={'100%'} />
    </div>
    
  </Grid>
  
  </Grid>

   
    
  
        </div>
    
    
      );
    

   
  

 }

