

import React from 'react';
import AboutEl  from '../Comps/aboutEl';
import {GLTRAN,GLSTYLE,GLTF, GLCON} from "../GLBL";
import illu from "../img/bg4.PNG";
import all from "../img/bonoo.jpg";
import farm from "../img/bg11.PNG";
import women from "../img/bg7.PNG";
import { Slide } from '@material-ui/core';
import man from "../img/bg9.PNG";
import HeaderEl from '../Comps/headerEl';
import child from "../img/bg10.PNG";
import mission from "../img/bg4.PNG";
import Button from '@material-ui/core/Button';
import blob1 from "../img/blob (6).svg";
import blob2 from "../img/blob (3).svg";
import Partners from './partners';
import ContactUs from '../Comps/contact';
import Footer from '../Comps/footer';
import blob3 from "../img/blob (5).svg";
import News from "../Comps/news";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideShow:1,
     newsData:[],
      };
  }


  componentDidMount() {
    this.props.pathfunc(window.location.href);
   this.GetData();
   if(localStorage.getItem("lang")){

   }else{
     localStorage.setItem("lang",'EN')
   }
 
  // setTimeout(() => {
  //   alert("yo")
  // }, 200);

  // clearTimeout();

    // setInterval(() => {

    //   if(this.state.slideShow==4){
    //     this.setState({
    //       slideShow: 0, 
    //       });  
    //   }

    //   this.setState({
    //     slideShow: this.state.slideShow +1, 
    //     });
    //   console.log(this.state.slideShow)
    // }, 2000);

     }


  GetData = () => {
    fetch(GLCON.SERVER_URL+'/cockpit/api/collections/get/news', {
   
    })
    .then(res=>res.json())
    .then(res => {
    console.log(res.entries);
  let arr = [];

    for (let i = 0; i < res.entries.length; i++) {
      if(i >= res.entries.length-6){
        arr.push(res.entries[i])
      }
    }

      this.setState({
     newsData:arr.reverse(), 
      });
    }
     
   
    );
  };



  dummyfunc=()=>{
    
  }




  render() {
 
   // alert(this.props.navtag);
 

    return (
  
      <>
        
      <div id="home" style={{padding:0,margin:0,overflow:'hidden',width:'100%'}}>


<HeaderEl  ind={this.state.slideShow} img={illu}  title={GLTF(GLTRAN.headerText_AR,GLTRAN.headerText_EN)} text={""} btnlink={"/what_we_do"} btntext={GLTF(GLTRAN.seeHow_AR,GLTRAN.seeHow_EN)} />



{/* 
<Partners pathfunc={this.dummyfunc} mar={0}/> */}




<div style={{width:'100%'}}>
{/* 
<AboutEl  link={"/about_us"} showmore={1} key={1} variant={"split"} dir={0} img={man}
 title={GLTF(GLTRAN.aboutElAboutUsTitle_AR,GLTRAN.aboutElAboutUsTitle_EN)}
 emphText={GLTF(GLTRAN.aboutElAboutUsEph_AR,GLTRAN.aboutElAboutUsEph_EN)}
  text={GLTF(GLTRAN.aboutElAboutUsText_AR,GLTRAN.aboutElAboutUsText_EN)} />

<AboutEl  link={"/about_us"} showmore={1} key={2} variant={"split"}  dir={1} img={mission}
 title={GLTF(GLTRAN.aboutElVisTitle_AR,GLTRAN.aboutElvisTitle_EN)} 
 emphText={GLTF(GLTRAN.aboutElvisEph_AR,GLTRAN.aboutElvisEph_EN)} 
 text={""} />

<AboutEl  link={"/about_us"} showmore={1} key={3} variant={"split"} dir={0} img={women}
 title={GLTF(GLTRAN.aboutElmisTitle_AR,GLTRAN.aboutElmisTitle_EN)} 
 emphText={GLTF(GLTRAN.aboutElmisEph_AR,GLTRAN.aboutElmisEph_EN)}
  text={GLTF(GLTRAN.aboutElmisText_AR,GLTRAN.aboutElmisText_EN)} />




<AboutEl  link={"/about_us"} showmore={1} key={4} variant={"split"} dir={1} img={child}
 title={GLTF(GLTRAN.aboutElvalTitle_AR,GLTRAN.aboutElvalTitle_EN)}
  emphText={GLTF(GLTRAN.aboutElvalEph_AR,GLTRAN.aboutElvalEph_EN)}
   text={GLTF(GLTRAN.aboutElvalText_AR,GLTRAN.aboutElvalText_EN)} />

   

<AboutEl  link={"/about_us"} showmore={1} key={4} variant={"split"} dir={0} img={farm}
 title={GLTF(GLTRAN.aboutElobjTitle_AR,GLTRAN.aboutElobjTitle_EN)}
  emphText={GLTF(GLTRAN.aboutElobjEph_AR,GLTRAN.aboutElobjEph_EN)}
   text={GLTF(GLTRAN.aboutElobjText_AR,GLTRAN.aboutElobjText_EN)} />



<AboutEl link={"/about_us"} showmore={1} key={5} variant={"center"} dir={1} img={all}
 title={GLTF(GLTRAN.aboutElplanTitle_AR,GLTRAN.aboutElplanTitle_EN)}
  emphText={GLTF(GLTRAN.aboutElplanEph_AR,GLTRAN.aboutElplanEph_EN)}
   text={GLTF(GLTRAN.aboutElplanText_AR,GLTRAN.aboutElplanText_EN)} />
          */}

</div>

{/* 

<div style={{paddingLeft:70,paddingRight:70,color:GLSTYLE.colors.blue.s900,fontWeight:'bold',fontSize:20,textAlign:localStorage.getItem("lang")==="AR"?'right':'left'}}>
  {GLTF(GLTRAN.latestNewsText_AR,GLTRAN.latestNewsText_EN)}
</div> */}

<div style={{color:GLSTYLE.colors.primary.s600,fontSize:20,fontWeight:'bold',textAlign:'center',marginTop:20}}>{GLTF(GLTRAN.latestNewsText_AR,GLTRAN.latestNewsText_EN)}</div>
<div style={{padding:0,paddingTop:30,display:'flex',justifyContent:'center',alignItems:"center",flexWrap:'wrap',width:"100%"}}>

{this.state.newsData.map((ob,index)=>{
 
return <News key={index} ob={ob} lang={localStorage.getItem("lang")}   content={localStorage.getItem("lang")==="EN"?ob.content_EN:ob.content_AR} date={ob.date} vid={ob.vid} imgs={ob.imgs}  /> 
  
  })}


</div>

<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

  
<Link style={{color:GLSTYLE.colors.blue.s900,textDecoration:'none',fontWeight:'bold'}} to="/news">
     
<div style={{color:'white',padding:10,borderRadius:5,backgroundColor:GLSTYLE.primary}}>
{GLTF(GLTRAN.ShowMoreText_AR,GLTRAN.ShowMoreText_EN)}
</div>
</Link>
  
</div>


{/* 
<div style={{padding:0}}>
<div style={{color:GLSTYLE.colors.primary.s600,fontSize:20,fontWeight:'bold',textAlign:'left',marginTop:20,padding:100,paddingBottom:0}}>
  {GLTF(GLTRAN.ourProgrammeTabText_AR,GLTRAN.ourProgrammeTabText_EN)}</div>



<AboutEl  link={"/programme"} showmore={1} key={2} variant={"split"}  dir={1} img={all} 
  title={GLTF(GLTRAN.aboutElDarTitle_AR,GLTRAN.aboutElDarTitle_EN)}
   emphText={GLTF(GLTRAN.aboutElDarEph_AR,GLTRAN.aboutElDarEph_EN)}
    text={GLTF(GLTRAN.aboutElDarText_AR,GLTRAN.aboutElDarText_EN)} />


  

<AboutEl link={"/programme"}  showmore={1} key={2} variant={"split"}  dir={0} img={all} 
title={GLTF(GLTRAN.aboutElGadTitle_AR,GLTRAN.aboutElGadTitle_EN)} 
  emphText={GLTF(GLTRAN.aboutElGadEph_AR,GLTRAN.aboutElGadEph_EN)} 
  text={GLTF(GLTRAN.aboutElGadText_AR,GLTRAN.aboutElGadText_EN)} />




  

<AboutEl link={"/programme"}  showmore={1} key={2} variant={"split"}  dir={1} img={all}
 title={GLTF(GLTRAN.aboutElKarTitle_AR,GLTRAN.aboutElKarTitle_EN)} 
  emphText={GLTF(GLTRAN.aboutElKarEph_AR,GLTRAN.aboutElKarEph_EN)} 
  text={GLTF(GLTRAN.aboutElkarText_AR,GLTRAN.aboutElKarText_EN)} />






  






</div>
 */}

<div style={{marginTop:30}}></div>



<Footer/>
</div>


    </>

    );
  }
}

export default Home;
 



   

 
  
 
  
   
