import React from "react";
import {GLSTYLE, GLTRAN,GLTF,GLCON, GLVARS} from "../GLBL";
import illu from "../img/contact-us-illustration.svg";
import AboutEl from "../Comps/aboutEl";
import Footer from "../Comps/footer";
import all1 from "../img/dar.jpg";
import all2 from "../img/gad.jpg";
import all3 from "../img/khar.jpg";
import blob2 from "../img/blob (3).svg";
import ContactUs from "./programme";

import {

  
  Link
} from "react-router-dom";
import Partners from "./partners";

class Programme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id:"home"
   
      };
  }


  // console.log(document.getElementById("aa").offsetTop);

    componentDidMount() {
      this.props.pathfunc(window.location.href);
      this.scrl();    


      }

      scrl = ()=>{
        setInterval(()=>{ 
          // let elmnt = document.getElementById(window.location.href.slice(window.location.href.lastIndexOf("#")+1,window.location.href.length));
          // elmnt.scrollIntoView();
  
          this.setState({ id: window.location.href.slice(window.location.href.lastIndexOf("#")+1,window.location.href.length) })
      // console.log(this.state.id)
        }, 1000);
       
       }

   
 render(){
      return (
        <>





<AboutEl showmore={0} key={2} variant={"split"}  dir={GLVARS.lang?1:0} img={null} 
  title={GLTF(GLTRAN.ourProgrammeTabText_AR,GLTRAN.ourProgrammeTabText_EN)}
   emphText={""}
    text={GLTF(GLTRAN.aboutElProgrammeText_AR,GLTRAN.aboutElProgrammeText_EN)} />


<span  id="org" style={{display:this.state.id=="darfur"?"block":"none"}} >
<AboutEl showmore={0} key={2} variant={"split"}  dir={1} img={all1} 
  title={GLTF(GLTRAN.aboutElDarTitle_AR,GLTRAN.aboutElDarTitle_EN)}
   emphText={GLTF(GLTRAN.aboutElDarEph_AR,GLTRAN.aboutElDarEph_EN)}
    text={GLTF(GLTRAN.aboutElDarTextMore_AR,GLTRAN.aboutElDarTextMore_EN)} />
</span>

  
<span  id="org" style={{display:this.state.id=="gadarif"?"block":"none"}} >
<AboutEl showmore={0} key={2} variant={"split"}  dir={0} img={all2} 
title={GLTF(GLTRAN.aboutElGadTitle_AR,GLTRAN.aboutElGadTitle_EN)} 
  emphText={GLTF(GLTRAN.aboutElGadEph_AR,GLTRAN.aboutElGadEph_EN)} 
  text={GLTF(GLTRAN.aboutElGadMoreText_AR,GLTRAN.aboutElGadMoreText_EN)} />
</span>



  
<span  id="org" style={{display:this.state.id=="khartoum"?"block":"none"}} >
<AboutEl showmore={0} key={2} variant={"split"}  dir={1} img={all3}
 title={GLTF(GLTRAN.aboutElKarTitle_AR,GLTRAN.aboutElKarTitle_EN)} 
  emphText={GLTF(GLTRAN.aboutElKarEph_AR,GLTRAN.aboutElKarEph_EN)} 
  text={GLTF(GLTRAN.aboutElkarText_AR,GLTRAN.aboutElKarText_EN)} />
</span>



<Footer/>
</>
    
      );
    

      }
  

 }


 export default Programme;
