
import { makeStyles, useTheme } from '@material-ui/core/styles';
import im1 from "../img/bg1.PNG";
import { useEffect } from 'react';
import im2 from "../img/bg2.PNG";
import im3 from "../img/bg3.PNG";
import im4 from "../img/bg4.PNG";
import im5 from "../img/bgg1.jpg";
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import {GLTRAN,GLSTYLE,GLTF} from "../GLBL";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    height:'100%',display:'flex',flexDirection:'row',justifyItems:'space-between',
    alignItems:'flex-start',paddingTop:70,
    [theme.breakpoints.down('sm')]: {
      textAlign:'center',
      paddingTop:70,
     },
  },

  imsty1: {
    marginTop: 0,
    overflowX:'hidden',
   
    width:'100%',
    boxShadow:GLSTYLE.box.raised,

    transition: theme.transitions.create('margin-top', {
      easing: theme.transitions.easing.sharp,
     
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  imsty2: {
  
    
    width:'100%',
    boxShadow:GLSTYLE.box.raised,
    transition: theme.transitions.create('margin-top', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: 0,
  },

  heroText:{
    width:'100%',textAlign:"center",
    [theme.breakpoints.down('sm')]: {
     textAlign:'center',
    },
  },
  heroDiv:{
    flexDirection:"column-reverse",
    alignItems:'center',
    justifyContent:'center',
       [theme.breakpoints.down('sm')]: {
      flexDirection:'column-reverse',
       },
      },
 
}));

export default function HeaderEl(props) {
  const classes = useStyles();
  const theme = useTheme();
  
  const [img, setImg] = React.useState(im1);
  const [ind, setInd] = React.useState(1);


  useEffect(() => {
   startint()
  }, []);


  const startint= ()=>{
   let co = 0
    setInterval(() => {

      if(co===5){
       co=1;
      }else{
        co++
       
      }
       console.log(co)
       changeImg(co)
       setInd(co)
      //changeImg(ind)
    }, 3000);

  }

  
const changeImg=(index)=>{
 switch(index){
   case 1:
     setImg(im1)
     break;

     case 2:
      setImg(im2)
      break;

      case 3:
        setImg(im3)
        break;

        case 4:
          setImg(im4)
          break;
          case 5:
            setImg(im5)
            break;
 }
}

  


  return (
 
    <div className={classes.root}  >


<Grid className={classes.heroDiv} container spacing={0}>

<Grid  item sm={3} xs={12}>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',width:"100%"}}>
<div className={classes.heroText}>
  <div style={{fontSize:20,fontWeight:"bold",padding:10,color:GLSTYLE.primary}}>
  {props.title}
  </div>
 

  

<div style={{backgroundColor:GLSTYLE.primary,padding:10,borderRadius:5,color:'white',display:"inline-block",margin:15}}>

  
<Link style={{color:"white",textDecoration:'none'}} to={props.btnlink}>{props.btntext}</Link>
    
</div>

</div>


</div>
</Grid>

<Grid  item sm={12} xs={12}>
  

<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:"column",width:'100vw'}}>
<div className={ ind%2==0?classes.imsty1:classes.imsty2} style={{display:"flex",alignItems:"center",justifyContent:"center"}} ><img  alt={""} src={img} width={'50%'} /></div>
<div style={{display:"flex",justifyContent:"center",alignContent:'center',marginTop:20}}>
  
  <div  style={{width:10,height:10,borderRadius:200,backgroundColor: ind===1? GLSTYLE.primary:"lightgrey",margin:5,cursor:'pointer'}}></div>
 
  <div style={{width:10,height:10,borderRadius:200,backgroundColor: ind===2? GLSTYLE.primary:"lightgrey",margin:5,cursor:'pointer'}}></div>

  <div  style={{width:10,height:10,borderRadius:200,backgroundColor: ind===3? GLSTYLE.primary:"lightgrey",margin:5,cursor:'pointer'}}></div>

  <div  style={{width:10,height:10,borderRadius:200,backgroundColor: ind===4? GLSTYLE.primary:"lightgrey",margin:5,cursor:'pointer'}}></div>

 </div>
</div>
</Grid>
</Grid>


<div style={{marginTop:50}}></div>


</div> 
    

  );
}