import React from "react";
import {GLSTYLE, GLTRAN,GLTF,GLCON} from "../GLBL";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import BasicModal from "./modal";
import arrow from "../img/arrow.svg";
import {

  
  Link
} from "react-router-dom";






const useStyles = makeStyles((theme) => ({
  root: {
    width:"20%", height:350,backgroundColor:'white',boxShadow:GLSTYLE.box.raised,borderRadius:15,padding:25,margin:10,
    [theme.breakpoints.down('sm')]: {
      padding:20,
      margin:10,
      marginTop:10,
      marginBottom:10,
     width:"100%",
     },
  },

  
}));
export default function News(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  return (
  
<div  className={classes.root} >
  <div onClick={()=>{handleOpen()}} style={{ width:'100%', display:'flex',flexDirection:"column",justifyContent:"space-between",alignItems:"center",height:"100%",direction:props.lang==="AR"?'rtl':'ltr'}}>
<div style={{overflow:"hidden"}}>
  {props.imgs?
  <img alt={""} src={GLCON.SERVER_URL+props.imgs[0].path} width={'100%'} />
  : <div>
   <video width="100%"  controls>
  <source src={props.vid} type="video/mp4"/>
 
 
</video>
  </div>

}

</div>

{/* 
<div style={{width:"80%",border:"1px solid "+GLSTYLE.primary}}></div> */}

<div style={{width:'100%',direction:props.lang==="AR"?'rtl':'ltr',overflowY:'scroll',maxHeight:"50%"}}>
    <div style={{color:GLSTYLE.primary,fontSize:14}}>{props.date}</div>
    <div style={{color:"black",fontSize:17}}>{props.content+"....."}</div>
</div>

     



  </div>
  <BasicModal open={open} text={props.content} isvid={props.imgs?false:true} media={props.imgs?props.imgs:props.vid} handleClose={()=>{handleClose()}} />
</div>


  );
}

