

import React from 'react';
import AboutEl  from '../Comps/aboutEl';
import {GLTRAN,GLSTYLE,GLTF} from "../GLBL";
import illu from "../img/org.PNG";
import all from "../img/bonoo.jpg";
import blob1 from "../img/blob (6).svg";
import blob2 from "../img/blob (3).svg";
import ContactUs from '../Comps/contact';
import Footer from '../Comps/footer';
import SubHeader from '../Comps/subHeader';
import blob3 from "../img/blob (5).svg";
import News from "../Comps/news";

class OrgStructure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     newsData:[],
      };
  }


  componentDidMount() {
    this.props.pathfunc(window.location.href);



     }




  render() {
 
   // alert(this.props.navtag);
 

    return (
  
      <>
        
    
    
      <div style={{padding:0,margin:0,overflow:'hidden',width:'100%'}}>
  <SubHeader img={illu} text={GLTF(GLTRAN.orgstructureText_AR,GLTRAN.orgstructureText_EN)} emphText={GLTF(GLTRAN.orgstructureEmphText_AR,GLTRAN.orgstructureEmphText_EN)} />

    
  








<Footer/>
</div>


    </>

    );
  }
}

export default OrgStructure;
 



   

 
  
 
  
   
