import React from "react";
import {GLSTYLE, GLTRAN,GLTF,GLCON} from "../GLBL";
import illu from "../img/contact-us-illustration.svg";
import blob2 from "../img/footer.svg";
import Grid from '@material-ui/core/Grid';
import ContactUs from "./contact";
import {

  
  Link
} from "react-router-dom";
export default function Footer(props) {


  // console.log(document.getElementById("aa").offsetTop);


   

      return (
        <>
     
     

<ContactUs id={"test"} key={2} variant={"split"}  dir={localStorage.getItem("lang")=="AR"?1:0}  title={GLTF(GLTRAN.contactsTabText_AR,GLTRAN.contactsTabText_EN)} 
  emphText={"The City of Al Jillie north of Khartoum and village is affected by Seoul and flooding"} 
  text={" many schools, health centers, and homes were affected by the floods, and most of them collapsed or were in danger of falling."}
   />

  <div style={{backgroundColor:GLSTYLE.primary,color:'white',display:'flex',flexDirection:"column",justifyContent:"center",alignItems:'center',textAlign:'center',fontWeigh:"bold",fontSize:10,height:200}}>
  <img alt={"Twaki Org Logo"} src={blob2} width={150}/>
{GLTF(GLTRAN.allRights_AR,GLTRAN.allRights_EN)}

       </div>
</>
    
      );
    

   
  

 }

