import { makeStyles, useTheme } from '@material-ui/core/styles';
import Home from "../pages/home";
import About from "../pages/about";
import Programme from "../pages/programme";
import Footer from "../Comps/footer";
import React from 'react';
import OrgStructure from "../pages/orgstruc";
import ContactPage from "../pages/contactpage";
import NewsList from "../pages/newslist";
import {FaBars,FaTimes} from 'react-icons/fa';
import blob2 from "../img/blob (3).svg";
import IconButton from '@material-ui/core/IconButton';
import Partners from "../pages/partners";
import WhatWeDo from "../pages/whatwedo";
import PhotoGallery from "../pages/photogallery";
import Article from "../pages/articles";
import { GLTRAN,GLTF,GLSTYLE } from "../GLBL";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import logo from "../img/blob (4).svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width:'100%'
  },
  deskMen:{
   display:'flex',
    [theme.breakpoints.down('sm')]: {
      display:'none'
     },
  },


  mobMen:{
    display:'none',
     [theme.breakpoints.down('sm')]: {
       display:'flex'
      },
   },
   menel:{
       fontSize:20,
       marginTop:20
   },

  
  rotate1:{
    zIndex:10,transform:"rotate(90deg)",
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    
   },

   
  rotate:{
    zIndex:10,transform:"rotate(0deg)",
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    
   },


  
  

   drawerOpenaux: {
    paddingLeft:20,paddingRight:20,display:'flex', width:'60%',position:'fixed',
    flexDirection:'column',height:"100vh",backgroundColor:'white',boxShadow:GLSTYLE.box.raised,
 
    marginLeft: -500,
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerCloseaux: {
    paddingLeft:20,paddingRight:20,display:'flex', width:'60%',position:'fixed',
    flexDirection:'column',height:"100vh",backgroundColor:'white',boxShadow:GLSTYLE.box.raised,
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  
  drawerOpenauxo: {
    position:'fixed',width:'30%',backgroundColor:"rgba(0,0,0,0.2)",right:0,height:"100vh",
  
    marginRight:-500,

    marginLeft: -500,
    transition: theme.transitions.create('margin-right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerCloseauxo: {
    position:'fixed',width:'30%',backgroundColor:"rgba(0,0,0,0.2)",right:0,height:"100vh",
   
    marginRight:0,
    transition: theme.transitions.create('margin-right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
   
  },


}));

export default function Menue(props) {
    const classes = useStyles();
    const theme = useTheme();
 
    const [open, setOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const opene = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [anchorElp, setAnchorElp] = React.useState(null);
    const openep = Boolean(anchorElp);
    const handleClickp = (event) => {
      setAnchorElp(event.currentTarget);
    };
    const handleClosep = () => {
      setAnchorElp(null);
    };
    
  



  return (
    <div id="a" style={{width:"100%"}} className={classes.root}>
    
    <Router>
      <div>
        <nav className={classes.deskMen} >
        
          <div style={{position:'fixed',width:'100%',display:"flex",justifyContent:"space-between",
          alignItems:'center',marginTop:0,fontSize:16,backgroundColor:GLSTYLE.primary ,boxShadow:GLSTYLE.box.raised}} >
    
       
         <div style={{padding:5,display:'flex', width:'20%', flexDirection:'row',justifyContent:'space-between'}}>
              <Link style={{color:GLSTYLE.primary,textDecoration:'none',paddingLeft:10}} to="/">
              <img alt={"Twaki Org Logo"} src={logo} width={120}/>
              </Link>
         </div>


             <div style={{paddingLeft:20,paddingRight:20,display:'flex', width:'80%', flexDirection:'row',justifyContent:'space-between'}}>
          <div>
              <Link style={{color:"white",textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/">{GLTF(GLTRAN.homeTabText_AR,GLTRAN.homeTabText_EN)}</Link>
         </div>
          <div  onClick={handleClick}> 
          <div style={{color:"white",textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us">{GLTF(GLTRAN.aboutTabText_AR,GLTRAN.aboutTabText_EN)}</div>
        
              </div>

         <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={opene}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
       
        <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us#org"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#org">
       
          {GLTF(GLTRAN.aboutTabText_AR,GLTRAN.aboutTabText_EN)}
          </Link>
          </MenuItem>
   
          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us/#vision"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#vision">
       
        {GLTF(GLTRAN.aboutElVisTitle_AR,GLTRAN.aboutElvisTitle_EN)}
          </Link>
          </MenuItem>
   
          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us/#objective"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#objective">
       
      {GLTF(GLTRAN.aboutElobjTitle_AR,GLTRAN.aboutElobjTitle_EN)  }
          </Link>
          </MenuItem>
   
          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us/#misison"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#mission">
       
        {GLTF(GLTRAN.aboutElmisTitle_AR,GLTRAN.aboutElmisTitle_EN)}
          </Link>
          </MenuItem>
   
          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us/#values"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#values">
       
        {GLTF(GLTRAN.aboutElvalTitle_AR,GLTRAN.aboutElvalTitle_EN)}
          </Link>
          </MenuItem>

          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us/#plan"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#plan">
       
        {GLTF(GLTRAN.aboutElplanTitle_AR,GLTRAN.aboutElplanTitle_EN)}
          </Link>
          </MenuItem>
   
        
   
      </Menu>
          <div>
              <Link style={{color:"white",textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/partners"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}}  to="/partners">{GLTF(GLTRAN.partnersTabText_AR,GLTRAN.partnersTabText_EN)}</Link>
         </div>
         <div>
              <Link style={{color:"white",textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/organization_structure"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/organization_structure">{GLTF(GLTRAN.orgStructureTabText_AR,GLTRAN.orgStructureTabText_EN)}</Link>
         </div>
         <div>
              <Link style={{color:"white",textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/what_we_do"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/what_we_do">{GLTF(GLTRAN.whatWeDoTabText_AR,GLTRAN.whatWeDoTabText_EN)}</Link>
         </div>
         <div>


              <div onClick={handleClickp} style={{color:"white",textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/programme"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/programme">{GLTF(GLTRAN.ourProgrammeTabText_AR,GLTRAN.ourProgrammeTabText_EN)}</div>
        
              <Menu
        id="basic-menu"
        anchorEl={anchorElp}
        open={openep}
        onClose={handleClosep}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
       
        <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClosep}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/programme#darfur"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/programme#darfur">
       
         {GLTF(GLTRAN.aboutElDarTitle_AR,GLTRAN.aboutElDarTitle_EN)}
          </Link>
          </MenuItem>
   
          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClosep}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/programme#gadarif"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/programme#gadarif">
       
         {GLTF(GLTRAN.aboutElGadTitle_AR,GLTRAN.aboutElGadTitle_EN)}
          </Link>
          </MenuItem>

          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClosep}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/programme#khartoum"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/programme#khartoum">
       
         {GLTF(GLTRAN.aboutElKarTitle_AR,GLTRAN.aboutElKarTitle_EN)}
          </Link>
          </MenuItem>
         
        
   
      </Menu>
        
        
         </div>

         <div>
              <Link style={{color:"white",textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/photo_gallery"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/photo_gallery">{GLTF(GLTRAN.photoGalleryTabText_AR,GLTRAN.photoGalleryTabText_EN)}</Link>
         </div>

         <div>
              <Link style={{color:"white",textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/contact-us"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white"}}  to="/contact-us">{GLTF(GLTRAN.contactsTabText_AR,GLTRAN.contactsTabText_EN)}</Link>
         </div>   

         <div onClick={()=>{props.changeLang()}} style={{padding:0,color:"white",borderRadius:5,cursor:'pointer'}}>
           {GLTF(GLTRAN.lang_AR,GLTRAN.lang_EN)}
            </div>
         </div>

       
       

          </div>
     
        </nav>


        <nav  className={classes.mobMen}>
        
        <div  style={{position:'fixed',width:'100%',display:"flex",
        justifyContent:"space-between",alignItems:'flex-start',marginTop:0,fontSize:16,
        backgroundColor:GLSTYLE.primary,boxShadow:GLSTYLE.box.lg}} >
  
     
       <div  style={{padding:10,display:'flex', width:'100%', flexDirection:'row',
       justifyContent:'space-between',alignItems:"center"}}>
            <Link style={{color:GLSTYLE.primary,textDecoration:'none',paddingLeft:10}} to="/">
            <img alt={"Twaki Org Logo"} src={logo} width={110}/>
            </Link>

            <div className={props.open?classes.rotate1:classes.rotate} onClick={()=>{props.handleDrawerOpen()}}>
            <IconButton style={{color:GLSTYLE.primary,backgroundColor:'white'}} >
               {props.open?<FaTimes/>:<FaBars/>} 
            
          </IconButton>
            </div>
       </div>


           <div className={props.open?classes.drawerCloseaux:classes.drawerOpenaux}>
               <div style={{marginTop:30}}></div>
<div style={{position:"absolute",zIndex:-1,top:500,right:20}}>
<img  alt={""} src={blob2} width={200} />
</div>



        <div onClick={()=>{setOpen(false)}} className={classes.menel}>
            <Link style={{color:GLSTYLE.colors.blue.s900,textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:'bold'}} to="/">{GLTF(GLTRAN.homeTabText_AR,GLTRAN.homeTabText_EN)}</Link>
       </div>
        <div className={classes.menel}>
           
          
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <div style={{color:GLSTYLE.colors.blue.s900,textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:'bold'}} to="/about_us">{GLTF(GLTRAN.aboutTabText_AR,GLTRAN.aboutTabText_EN)}</div>
         
        </AccordionSummary>
        <AccordionDetails>
         
          
        <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us#org"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#org">
       
          {GLTF(GLTRAN.aboutTabText_AR,GLTRAN.aboutTabText_EN)}
          </Link>
          </MenuItem>
   
          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us/#vision"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#vision">
       
        {GLTF(GLTRAN.aboutElVisTitle_AR,GLTRAN.aboutElvisTitle_EN)}
          </Link>
          </MenuItem>
   
          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us/#objective"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#objective">
       
      {GLTF(GLTRAN.aboutElobjTitle_AR,GLTRAN.aboutElobjTitle_EN)  }
          </Link>
          </MenuItem>
   
          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us/#misison"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#mission">
       
        {GLTF(GLTRAN.aboutElmisTitle_AR,GLTRAN.aboutElmisTitle_EN)}
          </Link>
          </MenuItem>
   
          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us/#values"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#values">
       
        {GLTF(GLTRAN.aboutElvalTitle_AR,GLTRAN.aboutElvalTitle_EN)}
          </Link>
          </MenuItem>

          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClose}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/about_us/#plan"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/about_us#plan">
       
        {GLTF(GLTRAN.aboutElplanTitle_AR,GLTRAN.aboutElplanTitle_EN)}
          </Link>
          </MenuItem>
        </AccordionDetails>
      </Accordion>
         
        
   
   


       </div>
        <div className={classes.menel}>
            <Link style={{color:GLSTYLE.colors.blue.s900,textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/partners"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:'bold'}}  to="/partners">{GLTF(GLTRAN.partnersTabText_AR,GLTRAN.partnersTabText_EN)}</Link>
       </div>
       <div className={classes.menel}>
            <Link style={{color:GLSTYLE.colors.blue.s900,textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/organization_structure"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:'bold'}} to="/organization_structure">{GLTF(GLTRAN.orgStructureTabText_AR,GLTRAN.orgStructureTabText_EN)}</Link>
       </div>
       <div className={classes.menel}>
            <Link style={{color:GLSTYLE.colors.blue.s900,textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/what_we_do"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:'bold'}} to="/what_we_do">{GLTF(GLTRAN.whatWeDoTabText_AR,GLTRAN.whatWeDoTabText_EN)}</Link>
       </div>
       <div className={classes.menel}>
           

            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
           <div style={{color:GLSTYLE.colors.blue.s900,textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/programme"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:'bold'}} to="/programme">{GLTF(GLTRAN.ourProgrammeTabText_AR,GLTRAN.ourProgrammeTabText_EN)}</div>
       
        </AccordionSummary>
        <AccordionDetails>
         
          
        <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClosep}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/programme#darfur"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/programme#darfur">
       
         {GLTF(GLTRAN.aboutElDarTitle_AR,GLTRAN.aboutElDarTitle_EN)}
          </Link>
          </MenuItem>
   
          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClosep}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/programme#gadarif"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/programme#gadarif">
       
         {GLTF(GLTRAN.aboutElGadTitle_AR,GLTRAN.aboutElGadTitle_EN)}
          </Link>
          </MenuItem>

          <MenuItem style={{fontFamily:"inherit",fontWeight:'bold',color:GLSTYLE.primary}} onClick={handleClosep}>
        <Link style={{textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/programme#khartoum"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:''}} to="/programme#khartoum">
       
         {GLTF(GLTRAN.aboutElKarTitle_AR,GLTRAN.aboutElKarTitle_EN)}
          </Link>
          </MenuItem>
        </AccordionDetails>
      </Accordion>
         
       
       
       </div>

       

       <div className={classes.menel}>
            <Link style={{color:GLSTYLE.colors.blue.s900,textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/photo_gallery"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:'bold'}} to="/photo_gallery">{GLTF(GLTRAN.photoGalleryTabText_AR,GLTRAN.photoGalleryTabText_EN)}</Link>
       </div>

       <div className={classes.menel}>
            <Link style={{color:GLSTYLE.colors.blue.s900,textDecoration:'none',paddingBottom:3,borderBottom: props.currentPage==="/contact-us"?'3px solid'+GLSTYLE.colors.green.s400:"0px solid white",fontWeight:'bold'}}  to="/contact-us">{GLTF(GLTRAN.contactsTabText_AR,GLTRAN.contactsTabText_EN)}</Link>
       </div>   
       <div onClick={()=>{props.changeLang()}} style={{padding:0,fontSize:20,marginTop:25,color:GLSTYLE.colors.blue.s700,borderRadius:5,fontWeight:'bold',cursor:'pointer'}}>
         {GLTF(GLTRAN.lang_AR,GLTRAN.lang_EN)}
          </div>


      
       </div>

       <div onClick={()=>{props.handleDrawerOpen()}} className={props.open?classes.drawerCloseauxo:classes.drawerOpenauxo} >

       </div>

     
     

        </div>
   
      </nav>

      
        <Switch>
          <Route path="/about_us">
            <About pathfunc={props.locations} />
          </Route>

          <Route path="/news">
            <NewsList pathfunc={props.locations}/>
          </Route>

          <Route path="/programme">
            <div style={{paddingTop:100}}>
            <Programme  pathfunc={props.locations}  />
            </div>
          </Route>
          
          <Route path="/contact-us">
            <ContactPage  pathfunc={props.locations}  />
          </Route>

          <Route path="/partners">
            <Partners pathfunc={props.locations} mar={1}   />
          </Route>

          <Route path="/organization_structure">
           <OrgStructure pathfunc={props.locations}    />
          </Route>

          <Route path="/what_we_do"> 
           <WhatWeDo pathfunc={props.locations}    />
          </Route>

          <Route path="/photo_gallery">
          < PhotoGallery pathfunc={props.locations}    />
          </Route>
            
          <Route path="/">
            <Home  pathfunc={props.locations} />
          </Route>
        </Switch>
      </div>
    </Router>

    </div>
    
  );
}