import React from "react";
import {GLSTYLE, GLTRAN,GLTF,GLCON} from "../GLBL";
import illu from "../img/contact-us-illustration.svg";
import AboutEl from "../Comps/aboutEl";
import Footer from "../Comps/footer";
import all from "../img/bonoo.jpg";
import blob2 from "../img/blob (3).svg";
import ContactUs from "./programme";

import {

  
  Link
} from "react-router-dom";
import Partners from "./partners";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
   
      };
  }


  // console.log(document.getElementById("aa").offsetTop);

    componentDidMount() {
      this.props.pathfunc(window.location.href);
    


      }

   
 render(){
      return (
        <>
<Footer/>
</>
    
      );
    

      }
  

 }


 export default ContactPage;
