import React from "react";
import {GLSTYLE, GLTRAN,GLTF,GLVARS} from "../GLBL";
import blob2 from "../img/blob (3).svg";
import blob1 from "../img/blob (6).svg";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {

  
  Link
} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  heroDiv:{
    display:'flex',
    //flexDirection:AboutEl.props.dir?'row-reverse':'row', 
    justifyContent:'center',alignItems:'center',
    padding:100,
    paddingBottom:50,
       [theme.breakpoints.down('sm')]: {
        padding:10,
        marginBottom:30,
       },
      },
    
      planText:{
        height:"100%",width:'60%',
        [theme.breakpoints.down('sm')]: {
          padding:10,
         width:"100%"
         },
      },

      mobFix:{
        display:"block",
        height:10,
        [theme.breakpoints.down('sm')]: {
          height:100,
         },
      },
 
}));


export default function SubHeader (props) {
  const classes = useStyles();
  const theme = useTheme();

  // console.log(document.getElementById("aa").offsetTop);


      return (

        <div style={{flexDirection:props.dir?'row-reverse':'row'}}>
          <div className={classes.mobFix}></div>  
<Grid className={classes.heroDiv} container spacing={0}>

<Grid style={{padding:15}}  item sm={6} xs={12}>
<div style={{height:"100%",width:'100%'}}>
    <div style={{color:GLSTYLE.colors.primary.s600,fontSize:20,fontWeight:'bold',textAlign:GLVARS.lang?"right":"left"}}>{props.title}</div>
    <div style={{color:GLSTYLE.colors.blue.s900,fontSize:40,fontWeight:'bold',marginTop:10,textAlign:GLVARS.lang?"right":"left"}}>
    {props.emphText}
    </div>
    
    <div style={{color:GLSTYLE.colors.gray.s800,fontSize:20,marginTop:10,textAlign:GLVARS.lang?"right":"left"}}>
    {props.text}
    </div>
    
    
    <div style={{display:props.showmore?'flex':"none",justifyContent:GLVARS.lang?'left':'right',alignItems:'center',marginTop:20}}>
    <div style={{color:'white',padding:10,marginRight:10,borderRadius:5,backgroundColor:GLSTYLE.primary}}>
      
    <Link style={{color:"white",textDecoration:'none'}} to={props.link}>{GLTF(GLTRAN.ShowMoreText_AR,GLTRAN.ShowMoreText_EN)}</Link>
        
    
    </div>
    </div>
    
    
    
    </div>
</Grid>

<Grid  style={{marginTop:20}} item sm={6} xs={12}>
<div style={{height:'100%',width:"100%",boxShadow:GLSTYLE.box.none,borderRadius:5}}>
    <img alt={""} style={{borderRadius:5}} src={props.img} width={'100%'} />
    </div>
  </Grid>
</Grid>
  
    
    
<div style={{position:"absolute",zIndex:-1,bottom:0,left:0}}>
<img  alt={""} src={blob1} width={300} />
</div>

<div style={{position:"absolute",zIndex:-1,top:100,right:20}}>
<img  alt={""} src={blob2} width={200} />
</div>









   
    
        </div>
    
    
      );
  

 }

