export const GLCON = {
    SERVER_URL: 'https://twaki.org',
  };
  //https://twaki.org
//http://localhost/twaki
  export const GLVARS = {
    lang: localStorage.getItem("lang")=="AR"?1:0,
  };



  export const GLSTYLE = {
  primary:'#2b6cb0',
  secondary:'#38a169',
 box:{
  xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
  sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  DEFAULT: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
  raised: '0 20px 50px rgba(0,0,0, 0.075)',
  none: 'none',
 },
 colors:{
  orange: {
    s100: '#fffaf0',
    s200: '#feebc8',
    s300: '#fbd38d',
    s400: '#f6ad55',
    s500: '#ed8936',
    s600: '#dd6b20',
    s700: '#c05621',
    s800: '#9c4221',
    s900: '#7b341e',
  },
  primary: {
    s100: '#a273ff',
    s200: '#935bff',
    s300: '#8344ff',
    s400: '#742cff',
    s500: '#6415FF',
    s600: '#5a13e6',
    s700: '#5011cc',
    s800: '#460fb3',
    s900: '#3c0d99',
  },
  green: {
    s100: '#f0fff4',
    s200: '#c6f6d5',
    s300: '#9ae6b4',
    s400: '#68d391',
    s500: '#48bb78',
    s600: '#38a169',
    s700: '#2f855a',
    s800: '#276749',
    s900: '#22543d',
  },
  blue: {
    s100: '#ebf8ff',
    s200: '#bee3f8',
    s300: '#90cdf4',
    s400: '#63b3ed',
    s500: '#4299e1',
    s600: '#3182ce',
    s700: '#2b6cb0',
    s800: '#2c5282',
    s900: '#2a4365',
  },
  gray: {
    "lightest": "#fff",
    s100: '#f7fafc',
    s200: '#edf2f7',
    s300: '#e2e8f0',
    s400: '#cbd5e0',
    s500: '#a0aec0',
    s600: '#718096',
    s700: '#4a5568',
    s800: '#2d3748',
    s900: '#1a202c',
  },
  purple: {
    s100: '#faf5ff',
    s200: '#e9d8fd',
    s300: '#d6bcfa',
    s400: '#b794f4',
    s500: '#9f7aea',
    s600: '#805ad5',
    s700: '#6b46c1',
    s800: '#553c9a',
    s900: '#44337a',
  },
 }



  };


  
   export const GLTRAN= {
    WelcomText_EN: 'Hello',
    WelcomText_AR: 'أهلا <br>',

    ////tabs
    aboutTabText_AR:'عن المنظمة',
    aboutTabText_EN:'About Org',
    homeTabText_AR:'الرئيسية',
    homeTabText_EN:'Home',
    partnersTabText_AR:'شركائنا',
    partnersTabText_EN:'Our Partners',
    orgStructureTabText_AR:'هيكلة المنظمة',
    orgStructureTabText_EN:'Our Organization Structure',
    whatWeDoTabText_AR:'ماالذي نفعله؟',
    whatWeDoTabText_EN:'What We Do?',
    ourProgrammeTabText_AR:'برامجنا',
    ourProgrammeTabText_EN:'Our Programme',
    photoGalleryTabText_AR:'معرض الصور',
    photoGalleryTabText_EN:'Photo Gallery',
    contactsTabText_AR:'إتصل بنا',
    contactsTabText_EN:'Contact Us',

    ShowMoreText_AR:'عرض المزيد ',
    ShowMoreText_EN:'Show More >',

    readMoreText_AR:'المزيد ...',
    readMoreText_EN:'More ...',

    headerText_AR:"لبناء مجتمع من غير فقر ",
    headerText_EN:"To build community without poverty.",
    headerSubText_AR:".نقدم مساعدات في مختلف المجالات الخيرية",
    headerSubText_EN:"We provide aid in various charity fields.",

    seeHow_AR:'أنظر كيف',
    seeHow_EN:'See How',

    lang_AR:"English",
    lang_EN:"العربية",

    latestNewsText_AR:"آخر الأخبار",
    latestNewsText_EN:"Latest News",

    //  test:
    //   <div>
    //   living in the rural area of East Darfur, and to displaced populations living in camps. The main activities implemented include:
    // <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
    //    ⦁	Agricultural</span>/horticultural support</div> 
    //    <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
    //    ⦁	Rehabilitation</span>  and reconstruction of water yards</div> 
    //    </div>

    contactUsText_AR:'للإستعلام و المقترحات تواصل معنا على',
    contactUsText_EN:'For More Information And Suggestions Contact Us On',
    
    gadarifOfficeContacts_AR:<div style={{textAlign:'right',marginTop:10,fontSize:13}}>
    <div style={{fontWeight:'bold',textAlign:'right',marginTop:10}}>مكتب القضارف </div>
       <div style={{textAlign:'right',marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",textAlign:'right'}}>
        	الموقع: </span>مربع السلمابي مبنى رقم 15 </div> 
      <div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
     	التواصل :</span>  محمد حجر  -  المدير المحلي    </div>

      <div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
     	رقم الهاتف:</span>00249128886689.
</div>

<div style={{textAlign:'right',marginTop:10}}>  info@twaki.org <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",textAlign:'right'}}>
      	:البريد الإلكتروني</span>
</div>
</div>,
    gadarifOfficeContacts_EN:  <div style={{marginTop:10,fontSize:13}}>
      <div style={{fontWeight:'bold',marginTop:10}}>Gadaref Office</div>
         <div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
           ⦁	Location: </span> AL slmabi Block 12-Bulding 15. </div> 
        <div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
        ⦁	Contact Person:</span>   Mohammed Hager - Area Manager.</div>

        <div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
        ⦁	Tel.No:</span>00249128886689.
</div>

<div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
        ⦁	Email:</span> info@twaki.org
</div>
</div>,


khartoumOfficeContacts_AR:<div style={{textAlign:'right',marginTop:20,fontSize:13}}>
<div style={{fontWeight:'bold',textAlign:'right',marginTop:10}}>مكتب الخرطوم </div>
   <div style={{textAlign:'right',marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",textAlign:'right'}}>
      الموقع: </span> شارع الستين - المعمورة </div> 
  <div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
   التواصل :</span>   نون حماد عبد الله-  المدير العام</div>

  <div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
   رقم الهاتف:</span> 00249912818077, 0912565764, 0155130177
</div>

<div style={{textAlign:'right',marginTop:10}}>  info@twaki.org <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",textAlign:'right'}}>
    :البريد الإلكتروني</span>
</div>
</div>,
khartoumOfficeContacts_EN:  <div style={{marginTop:20,fontSize:13}}>
  <div style={{fontWeight:'bold',marginTop:10}}>Khartoum Office</div>
     <div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
       ⦁	Location: </span>AL Steen Street ,ALmamora. </div> 
    <div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
    ⦁	Contact Person:</span>   Noon Hamad Abdallah- Director General</div>

    <div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
    ⦁	Tel.No:</span>00249912818077, 0912565764, 0155130177
</div>

<div style={{marginTop:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
    ⦁	Email:</span> info@twaki.org
</div>
</div>,

allRights_AR:'2021 جميع الحقوق محفوظة منظمة التواكي للتنمية',
allRights_EN:'All Rights reserved Al twaki Development Organization 2021',





aboutElAboutUsTitle_AR:"عن المنظمة",
aboutElAboutUsTitle_EN:"About Org ",

aboutElAboutUsEph_AR:"منظمةالتواكي للتنمية هي منظمة وطنية(غير حكومية) تأسست عام 2013. ",
aboutElAboutUsEph_EN:"Al Tawaki is a national non-governmental organization-(NGO) established in 2013,",

aboutElAboutUsText_AR:"وهي تعمل في ولايتي شرق دارفور والقضارف ، تعمل المنظمة في عدة مجالات و أنشطة :الأمن الغذائي وتحسين سبل العيش ،  وتقدم خدمات الإصحاح البيئي والنظافة الصحية للنازحين داخلياً في الولايتين ، وتدعم الفئات ",
aboutElAboutUsText_EN:"Supporting vulnerable populations in East/North Darfur, mainly in Adilla& Abu Karinka  and EL-Fashir . Al-Tawaki implements the interventions through strong relationships with the  local authorities  and some organizations.",
aboutElAboutUsMoreText_AR:"منظمةالتواكي للتنمية هي منظمة وطنية(غير حكومية) تأسست عام 2013. وهي تعمل في ولايتي شرق دارفور والقضارف ، تعمل المنظمة في عدة مجالات و أنشطة :الأمن الغذائي وتحسين سبل العيش ، وتقدم خدمات الإصحاح البيئي والنظافة الصحية للنازحين داخلياً في الولايتين ، وتدعم الفئات الضعيفة للسكان.تعمل منظمةالتواكي للتنمية مع النازحين لتقديم خدمات الطوارئ, وفي الوقت نفسه  تعمل على تمكين الأسر ومنظمات المجتمع المدني (CSO) من تحسين الأعراف والعادات الاجتماعية والتعليم وتحسين الحالةالاقتصادية خاصة بالنسبة للشباب والنساء,تنفذ منظمة التواكي للتنمية مشاريعها من خلال علاقات قوية مع السلطات المحلية وبعض المنظمات العالمية مثل (زوا ، الفاو ، المنظمة الدولية للهجرة ، اليوناميد ، UMCOR ، الهيئة الحكومية الدولية للتنمية ، برنامج الأغذية العالمي ,قطر الخيرية,اليونيسيف ومنظمة	GOAL) مع وجود لجان القطاع الخاص والمجتمعات المحلية.",

aboutElAboutUsMoreText_EN:"Supporting vulnerable populations in East/North Darfur, mainly in Adilla& Abu Karinka  and EL-Fashir . Al-Tawaki implements the interventions through strong relationships with the  local authorities  and some organizations (ZOA , FAO, IOM, UNAMID, UMCOR, IGAD, WFP, EID & Sand and Qatar charity), with the presence of private sectors and communities committees.  AL-Tawaki works with IDPs in Adilla locality to provide emergency services. At the same time it works to empower households and Civil Society Organizations( CSO) to improve social norms, education and economic situations especially for youth and women group.",



aboutElVisTitle_AR:" رؤيتنا",
aboutElvisTitle_EN:"Our Vision ",

aboutElvisEph_AR:"بناء مجتمع خالي من الفقر.",
aboutElvisEph_EN:"To build community without poverty.",




aboutElobjTitle_AR:"أهدافنا",
aboutElobjTitle_EN:"Our Objectives",

aboutElobjEph_AR:"تمكين الأسر والمجتمعات من أجل تحسين سبل العيش والوضع الاقتصادي.",
aboutElobjEph_EN:"To enhance Peace building by reducing resources conflict among farmers and nomads.",

aboutElobjText_AR:<div style={{direction:"rtl"}}>
 <div>⦁	بناء قدرات النساء لقيادة التغيير المجتمعي والتصدي لكل التحديات على المدى الطويل.</div>
 <div>⦁	تحسين الوصول إلى التعليم الجيد وخاصة بالنسبة للفتيات اللائي لم يجدن فرص للتعلم. وزيادة المهارات والمعرفة التي تركز على محو الأمية لدى الشباب.</div>
 <div>⦁	دعم المشردين داخليا وخدمات اللاجئين خلال حالات الطوارئ .</div>
 <div>⦁	تحسين خدمات الصرف الصحي والنظافة الصحية في المجتمعات.</div>
</div>,
aboutElobjText_EN:<div>
  <div>⦁	To increase children enrollment in basic education especially girls.</div>
  <div>⦁	To improve access for local community to primary health care.</div>
  <div>⦁	To empower households to improve the Livelihood  and economic situation.</div>
  <div>⦁	To improve Water, Sanitation and Hygiene promotion across communities.</div>
</div>,





aboutElmisTitle_AR:"مهمتنا",
aboutElmisTitle_EN:"Our Mission",

aboutElmisEph_AR:"نحن نعمل مع المجتمع ",
aboutElmisEph_EN:"We are working with community",

aboutElmisText_AR:"لبناء القدرات النسائيةوالشبابية لقيادة واتخاذ قرار تغيير المجتمع لمواجهة أي تحديات وتحسين الحياة المجتمعية التي تهدف إلى إنهاء الفقروالجوع",
aboutElmisText_EN:"building women capacity to lead/decide community‘s change addressing any challenges and improving community life aiming to end hunger.",

aboutElmisMoreText_AR:<div style={{direction:'rtl'}}>
<div style={{marginBottom:10}}>
لبناء القدرات النسائيةوالشبابية لقيادة واتخاذ قرار تغيير المجتمع لمواجهة أي تحديات وتحسين الحياة المجتمعية التي تهدف إلى إنهاء الفقروالجوع
</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁	تمكين</span> الأسر والمجتمعات من أجل تحسين سبل العيش والوضع الاقتصادي.
</div> 
<div style={{marginBottom:10}} > <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁	بناء</span>  قدرات النساء لقيادة التغيير المجتمعي والتصدي لكل التحديات على المدى الطويل.</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁	تحسين </span>  الوصول إلى التعليم الجيد وخاصة بالنسبة للفتيات اللائي لم يجدن فرص للتعلم. وزيادة المهارات والمعرفة التي تركز على محو الأمية لدى الشباب.

</div> 
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁	دعم  </span>  المشردين داخليا وخدمات اللاجئين خلال حالات الطوارئ .

</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁	تحسين </span>  خدمات الصرف الصحي والنظافة الصحية في المجتمعات.
</div>
</div>,
aboutElmisMoreText_EN:<div>
  <div style={{marginBottom:10}}>
  building women capacity to lead/decide community‘s change addressing any challenges and improving community life aiming to end hunger.
</div>
  <div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁	To empower</span>  households and communities in order to improve the Livelihood  and economic status.
</div> 
<div style={{marginBottom:10}} > <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁	Building</span> capacity of women to lead the community change and to address their own challenges over the long term</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁	To Improve </span>  access to quality learning especially for girls extending learning opportunities to increase skills and knowledge focusing on youth literacy.
</div> 
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁	Supporting IDPs </span>  and Refugee services during emergency and recovery situations.
</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁	To improve </span>  Water Sanitation and Hygiene promotion across communities.
</div>
</div>,




aboutElvalTitle_AR:"قيمنا",
aboutElvalTitle_EN:"Our Values",

aboutElvalEph_AR:"",
aboutElvalEph_EN:"Our members remain connected with communities, families, mothers and children",

aboutElvalText_AR:<div style={{direction:'rtl'}}>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ الانفتاح:</span>  تبادل المعلومات والموارد على نطاق واسع ، وتشجيع الحوار وتبادل المعرفة بأفضل الطرق.
 </div> 
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁  الاستفادة </span> الاستفادة من الخبرة المحلية والمعرفة.
</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ مشاركة المجتمع المدني: </span>  مشاركة المجتمع المدني: بناء شراكات لتمكين الأسر والمجتمعات والمنظمات المدنية المحلية.
.</div> 
</div>,
aboutElvalText_EN:<div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ Openness</span> : Widely sharing information and resources, encouraging dialogue and exchanging best practice knowledge. </div> 
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ Utilizing </span> Local Experience and Knowledge.</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ Participation of Civil Society </span> :  Building partnerships to empower families, communities and local civic organizations.</div> 
</div>,
aboutElvalMoreText_AR:<div style={{direction:'rtl'}}>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ الانفتاح:</span>  تبادل المعلومات والموارد على نطاق واسع ، وتشجيع الحوار وتبادل المعرفة بأفضل الطرق.
 </div> 
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁  الاستفادة </span> الاستفادة من الخبرة المحلية والمعرفة.
</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ مشاركة المجتمع المدني: </span>  مشاركة المجتمع المدني: بناء شراكات لتمكين الأسر والمجتمعات والمنظمات المدنية المحلية.
.</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ المساءلة: </span>   المساءلة من تحقيق اهداف المشاريع ومراقبة جودة أداءالعمل . 
</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁  تنوع  </span>  القدرات الجماعية مع جميع الشركاء ، وتعزيز التعاون لتعزيز القدرات</div> 

</div>,
aboutElvalMoreText_EN:<div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ Openness</span> : Widely sharing information and resources, encouraging dialogue and exchanging best practice knowledge. </div> 
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ Utilizing </span> Local Experience and Knowledge.</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ Participation of Civil Society </span> :  Building partnerships to empower families, communities and local civic organizations.</div> 
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁ Accountable </span>  : Accountability to monitor our work. 
</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>⦁  Collective Capacity </span> diversity with all partners, promoting collaboration to strengthen the capacity</div> 

</div>,






aboutElplanTitle_AR:"خطتنا",
aboutElplanTitle_EN:"Our Plan",

aboutElplanEph_AR:"تتمثل الخطة الإستراتيجية للفترة 2015 - 2020 في تحديد الاتجاه الاستراتيجي العام لتوجيه .",
aboutElplanEph_EN:"The Strategic Plan for 2015 – 2021 is to establish the overall strategic direction to guide the staff,",

aboutElplanText_AR:<div style={{direction:'rtl'}}>
الموظفين ومجلس الإدارة وأعضاء منظمة التواكي للتنمية خلال السنوات الخمس القادمة
<div>الأولويات الإستراتيجية :</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}> ⦁	التركيز</span>  على تعزيز اتجاه برامج المنظمة </div> 
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}> ⦁	رفع </span>  ملف التعريف الخاص بنا وزيادة مشاركة الموظفين والشركاء
</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}> ⦁	زيادة </span>   تمويلنا لخدمة مجتمعات شرق دارفور والقضارف.
</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}> ⦁	تعزيز </span>  قدرتنا على تحقيق الأهداف التنظيمية.
</div>
</div>,
aboutElplanText_EN:<div>
Board of Directors, and members of al-Tawaki Organization over the next six years.
<div>Strategic Priorities:</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}> ⦁	Focusing</span>  on strengthening our program direction </div> 
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}> ⦁	Raising </span> our profile and increasing members and partners engagement.
</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}> ⦁	Growing </span>  our funding to serve East Darfur and Gadaref communities.
</div>
<div style={{marginBottom:10}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}> ⦁	Strengthening </span>  our capacity to achieve organizational goals.
</div>
</div>,



aboutElDarTitle_AR:"ولايات دارفور",
aboutElDarTitle_EN:"Darfur States",

aboutElDarEph_AR:"يركز البرنامج في الغالب على السكان المتضررين من النزاع ",
aboutElDarEph_EN:"The programme focuses predominantly on the conflict-affected populations",

aboutElDarText_AR:"",
aboutElDarText_EN:<div>
living in the rural area of East Darfur, and to displaced populations living in camps. The main activities implemented include:
<div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
 ⦁	Agricultural</span>/horticultural support</div> 
 <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
 ⦁	Rehabilitation</span>  and reconstruction of water yards</div> 
</div>,
    aboutElDarTextMore_AR:<div style={{direction:"rtl"}}>
    الذين يعيشون في المناطق الريفية في شرق دارفور ، وعلى السكان المشردين الذين يعيشون في المخيمات. تشمل الأنشطة الرئيسية المنفذة ما يلي:

     <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
     ⦁	الدعم الزراعي /</span> البستاني.</div> 
     <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
     ⦁	انشاء/</span> إعادة تأهيل مصادر المياه.</div>
    
    
    
     <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
     ⦁		مشاريع</span>  تنمية المجتمع.
    </div>
    
     <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
     ⦁		النشاطات الاجتماعية</span>    المختلفة بما في ذلك التدريب على تطوير المهارات.
    </div>
    
     <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
     ⦁	دعم</span>   الأفراد المستضعفين بانشطة مدره للدخل</div> 
    
     <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
     ⦁	الاستجابة</span> 	 لحالات الطوارئ بما في ذلك توزيع المواد الغذائية وغير الغذائية.  </div>
    </div>,
    aboutElDarTextMore_EN:<div>
living in the rural area of East Darfur, and to displaced populations living in camps. The main activities implemented include:
<div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
 ⦁	Agricultural</span>/horticultural support</div> 
 <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
 ⦁	Rehabilitation</span>  and reconstruction of water yards</div>



 <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
 ⦁		Community</span>   development projects.
</div>

 <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
 ⦁		Various</span>   social activity including skills training.
</div>

 <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
 ⦁	Support</span>  to extremely vulnerable individuals</div> 

 <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
 ⦁	Emergency</span> 	 response including distribution of food and non- food items (NFIs).  </div>
</div>,

aboutElProgrammeText_AR:"يتم تنفيذ البرنامج من خلال المكتب الميداني بينما يدعم المكتب القُطري في الخرطوم العمليات. يتم تنفيذ جميع الأنشطة من خلال نهج قائم على المجتمع حيث يعد التطور والتنمية المدفوعان من المجتمع نفسه مكونين رئيسيين.",
aboutElProgrammeText_EN:<div style={{color:GLSTYLE.colors.blue.s900}}>
  The programme is implemented through the field office while a Country Office in Khartoum supports the operations. The activities are all carried out with a community based approach where community driven recovery and development are key components.
 15 national staff members are currently employed in the operations.

</div>,

    aboutElGadTitle_AR:"ولاية القضارف",
    aboutElGadTitle_EN:"AL Gadaref State",
    
    aboutElGadEph_AR:"لدى منظمةالتواكي للتنمية خمس مشاريع حالية في القضارف تساهم ",
    aboutElGadEph_EN:"AL-Twaki has three current projects in Al -Gadaref contributing ",
    
    aboutElGadText_AR:"",
    aboutElGadText_EN:<div>
    to Food security, Civil Society organizations and Migrants services in detention. Our current activities are:
        <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
        ⦁	Food</span> Security Project</div> 
        <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
        ⦁	Civil Society</span>  Project  Activities 
 </div> 
 <div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}>
        ⦁	Migrants  </span> Project 
 </div>     
 </div>,

aboutElGadMoreText_AR:<div style={{direction:"rtl"}}>
في الأمن الغذائي ، ومنظمات المجتمع المدني ، وخدمات المهاجرين قيد الاحتجاز. أنشطتنا الحالية هي:

    <div style={{marginTop:15,fontSize:25}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",fontSize:25}}>
    ⦁	مشروع</span>  الأمن الغذائي:</div> 
    <div style={{padding:10}}>
    <div style={{marginTop:10}} >⦁	بناء / تجديد البنية التحتية لحصاد المياه للزراعة.</div>
    <div  style={{marginTop:5}}>⦁	بناء / تجديد البنية التحتية لنشر المياه في المراعي.</div>
    <div  style={{marginTop:5}}>⦁	بناء / تجديد نقاط المياه للماشية.</div>
    <div  style={{marginTop:5}}>⦁	تعزيز طرق توزيع المياه للزراعة.</div>
    <div  style={{marginTop:5}}>⦁	تحسينات على المسارات والطرق للثروة الحيوانية ومناطق الرعي.</div>
    <div  style={{marginTop:5}}>⦁	دعم نظم الرعاية لصحة للحيوان.</div>
    <div  style={{marginTop:5}}>⦁	التدريب على أساليب محسنة لتربية الحيوانات.</div>
    <div  style={{marginTop:5}}>⦁	التدريب على الممارسات الزراعية المحسنة.</div>
    <div  style={{marginTop:5}}>⦁ تدريب علي زراعةالخضار والفواكه في الحدائق المنزلية.</div>
    <div  style={{marginTop:5}}>⦁	دعم إنتاج الفاكهة والخضروات.</div>
    <div  style={{marginTop:5}}>⦁	الترويج لإنتاج الدواجن.</div>
    <div  style={{marginTop:5}}>⦁	التدريب على تجهيز الأغذية.</div>
    <div  style={{marginTop:5}}>⦁	دريب على إنتاج وبيع المواقد ذات الكفاءة في استهلاك الوقود.</div>
    <div  style={{marginTop:5}}>⦁	دعم تنمية المشاريع الصغيرة.</div>
    </div>

    <div style={{marginTop:15,fontSize:25}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",fontSize:25}}>
    ⦁	أنشطة مشروع</span>  المجتمع المدني: </div> 
    
    <div style={{padding:10}}>
    يتم اختيار المزارعين والمنتجين للحيوانات للمشاركة في التوعية للمجتمع.
    <div style={{marginTop:5}} >⦁	مناقشة مفاهيم الأمن الغذائي والتغذية ACT 2011.</div>
    <div  style={{marginTop:5}}>⦁	يتم تسجيل وتدريب المزارعين ومجموعات الإنتاج السمكي والحيواني رسميًا ليصبحوا كيانات قانونية في مدينة القضارف ، كما يتم دعم منظمات المجتمع المدني التي تم تأسيسها حديثًا من قبل رابطة المزارعين وتشبيك الجمعيات الزراعية والرعوية كل 20 جمعية لتشكل مظلة ذات كيان يمكنها من قيادة المجتمع الريفي والنهوض باحتياجات المزارع والراعي وتطوير القطاعين الزراعي والرعوي.</div>
    <div  style={{marginTop:5}}>⦁	زادت جمعيات المزارعين والمنتجين للحيوانات من قدراتهم الإدارية والتقنية.</div>
    <div  style={{marginTop:5}}>⦁	حسنت جمعيات المزارعين والمنتجين للحيوانات قدرات المشاركة والتواصل مع مقدمي الخدمات والسلطات المحلية.</div>
    <div  style={{marginTop:5}}>⦁	تشكل المظلات قاعدة موارد لتبادل أفضل الممارسات وتقديم الدعم لمنظمات المجتمع المدني الأخرى.</div>
    <div  style={{marginTop:5}}>⦁	تسهيل تقديم الخدمات لمنظمات المجتمع المدني.</div>
    <div  style={{marginTop:5}}>⦁	التواصل والمعلومات وتبادل الخبرات.
</div>
    </div>

    <div style={{marginTop:15,fontSize:25}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",fontSize:25}}>
    ⦁	مشروع  </span>  المهاجرين  </div> 
    
    <div style={{padding:10}}>
    ⦁	تهدف الاتفاقية إلى توفير خدمات الدعم والحماية للمهاجرين المحتجزين في جميع أنحاء ولاية القضارف ، مع التركيز على النساء والأطفال والمسنين بمن فيهم العمال الموسميين.

    <div style={{marginTop:5}} >⦁ توفير الترجمة والنصائح القانونية للمهاجرين في ولاية القضارف.</div>
    <div  style={{marginTop:5}}>⦁	توفير وجبة يومية للمهاجرين المحتجزين في الحراسات في الولاية.</div>
    <div  style={{marginTop:5}}>⦁	توفير الصابون ومعجون الأسنان وفرش الأسنان ومستلزمات النظافة للمهاجرين.</div>
    </div>

    <div style={{marginTop:15,fontSize:25}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",fontSize:25}}>
    ⦁	مشروع  </span>   المياه واصحاح البيئة:  </div> 
    
    <div style={{padding:10}}>
    بدعم من منظمة اليونسيف يهدف المشروع الي تحسين الوضع البيئي في محليتي القلابات والقوريشة ويشمل المشروع 83 مجتمع في المحليتين ويهدف المشروع لتوفير مراحيض صحية وتدريب المجتمعات علي السلوك الصحي السليم .

    </div>


    <div style={{marginTop:15,fontSize:25}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",fontSize:25}}>
    ⦁	مشروع  </span>   تحسين التغذية:
  </div> 
    
    <div style={{padding:10}}>
    المساهمة في تحسين حالة التغذية بالنسبة للأطفال دون سن الخامسة من سوء التغذية المعتدل، والنساء الحوامل والمرضعات في المجتمعات المحلية المختارة في محليةالفشقة - ولاية القضارف .
وسيبدأ المشروع بتقييم التغذية لضمان العلاج وحالات  الطفل/الأم . ورصد وإدارة الأطفال دون سن الخامسة والنساء الحوامل والمرضعات .
 وسيوفر المشروع التدريب الأساسي للأمهات اللاتي تم علاجهن من خلال مراكز التغذية من خلال مراكز التغذية لتقديم الممارسات الجيدة وإدارة حالات المرض من أجل السيطرة على صحة الأطفال والنساء.   كما سيدعم المشروع تعزيز النظافة الغذائية، لتحسين الممارسات الجيدة وتعزيز السلوكيات المتغيرة في مجال الصحة للتصدي لأمراض الإسهال والحمل المتعلقة بخدمات التغذية في مراكز التغذية في منطقة الفشقةبالتعاون المغلق مع منظمة الصحة والتنمية في صحة الأطفال والمتطوعين المجتمعيين.  وسيزيد المشروع من الوعي بخدمات التغذية لاستخدام الأغذية الغنية بالحديد والفيتامينات.

     </div>
   

  
</div>,
 aboutElGadMoreText_EN:<div>
 to Food security, Civil Society organizations and Migrants services in detention. Our current activities are:
     <div style={{marginTop:15,fontSize:25}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",fontSize:25}}>
     ⦁	Food</span> Security Project:</div> 
     <div style={{padding:10}}>
     <div style={{marginTop:10}} >⦁	Construction / renovation of water harvesting infrastructure for agriculture.</div>
     <div  style={{marginTop:5}}>⦁	Construction / renovation of water spreading infrastructure for pastures.</div>
     <div  style={{marginTop:5}}>⦁		Construction / renovation of water points for livestock.</div>
     <div  style={{marginTop:5}}>⦁	Promotion of water harvesting methods for farming.</div>
     <div  style={{marginTop:5}}>⦁	Improvements to livestock corridors and grazing areas.</div>
     <div  style={{marginTop:5}}>⦁	Support to community-based animal health care systems.</div>
     <div  style={{marginTop:5}}>⦁	Training on improved methods for animal husbandry.</div>
     <div  style={{marginTop:5}}>⦁	Training on improved agricultural practices.</div>
     <div  style={{marginTop:5}}>⦁	Home gardening training.</div>
     <div  style={{marginTop:5}}>⦁	Support to fruit and vegetable production.</div>
     <div  style={{marginTop:5}}>⦁	Promotion of poultry production.</div>
     <div  style={{marginTop:5}}>⦁	Training on food processing.</div>
     <div  style={{marginTop:5}}>⦁	Training on production and sale of fuel-efficient stoves.</div>
     <div  style={{marginTop:5}}>⦁	Support to small enterprise development.</div>
     </div>

     <div style={{marginTop:15,fontSize:25}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",fontSize:25}}>
     ⦁	Civil Society</span>  Project  Activities </div> 
     
     <div style={{padding:10}}>
     Farmers and animal producers are selected to participate in awareness raising and sensitization in associations.

     <div style={{marginTop:5}} >⦁	Discuss concepts of food security and nutrition and ACT 2011.</div>
     <div  style={{marginTop:5}}>⦁	Farmers, fishery- and animal production groups are officially registered and trained to become legal entities. In Gadaref, the newly established CSOs will be supported by the farmer associations/and umbrella network from East/West Galabat .</div>
     <div  style={{marginTop:5}}>⦁	Farmer/Animal Producer Associations have increased managerial and technical skills and capacities.</div>
     <div  style={{marginTop:5}}>⦁	Farmer /Animal Producer associations have improved engagement, lobby and advocacy skills and capacities.</div>
     <div  style={{marginTop:5}}>⦁	Umbrellas set up resource base to share best practices and provide support to other CSOs.</div>
     <div  style={{marginTop:5}}>⦁	Facilitating provision of services to civil society organizations.</div>
     <div  style={{marginTop:5}}>⦁	Networking, information and experience sharing.</div>
     </div>

     <div style={{marginTop:15,fontSize:25}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",fontSize:25}}>
     ⦁	Migrants  </span> Project  </div> 
     
     <div style={{padding:10}}>
     The Agreement is to provide support and protection services to vulnerable migrants in the detention across Al Gadarif state, with focus on Women, Children and elderly including the seasonal workers.
     <div style={{marginTop:5}} >⦁  Provision of translation and legal advices for migrants in Gadaref state.</div>
     <div  style={{marginTop:5}}>⦁	Provision of daily meal for migrants in detentions in Gadaref State.</div>
     <div  style={{marginTop:5}}>⦁	Provision of soap, tooth paste, tooth brushes and hygiene kits for migrants.</div>
     </div>

     <div style={{marginTop:15,fontSize:25}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",fontSize:25}}>
     ⦁	WASH  </span>  Project  </div> 
     
     <div style={{padding:10}}>
   
With UNICAF partnership the project is designed to improved sanitation and behaviour change within a community; produce sustainable facilities and services through engagement with local natural leaders, markets and artisans; and promote adaptation and replication at scale through local capacity building and designs.
CLTS project helps communities to become ODF, which creates sanitary environment in a sustainable manner. Hygiene promotion and water safety practices generate demand and leadership for improved sanitation and behavior change within a community
The project activities :


3 - Hygiene promotion .
     <div style={{marginTop:5}} > 1 – Community-based sanitation and hygiene promotion.</div>
     <div  style={{marginTop:5}}> 2 – Household water treatment and safe storage   </div>
     <div  style={{marginTop:5}}>⦁	Provision of soap, tooth paste, tooth brushes and hygiene kits for migrants.</div>
     </div>

     <div style={{marginTop:15,fontSize:25}}> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",fontSize:25}}>
     ⦁	Nutrition  </span>  project   </div> 
     
     <div style={{padding:10}}>
     WITH Goal partnership the project started by nutrition assessment to ensure of treatment and referral cases to from MAM cases to conduct MUAC screening campaign to support child/mother to attend NIPP training and monitoring the management of under five children and Pregnant Lactating Women -PLWs with MAM protocol.

The project provided basic training for mothers who had MAM cases treatment through nutrition centres to deliver good practice and management of SAM cases to control children and women health. 

The project also support food hygiene promotion, to improve good practices and enhance health changing behaviours to address diarrhoea disease and pregnancy related to nutrition services at nutrition centres in two localities in  closed collaboration with SMOH and community volunteers.

The project increase the awareness of nutrition services to use of iron rich foods and vitamins .

The project avail supplies and equipment necessary for NIPP program in coordination with SMOH and communities volunteer and the beneficiaries.  Training sessions will be provided to the beneficiaries have the Children who with MAM to enrol to receive nutritional check-up. 

To ensue of sustainability nutrition services, the project will provide training for 8 from community volunteer in nutritional practice to support Integration Management of Childhood Illness (IMCI). 

   </div>
    

   
</div>,

 
aboutElKarTitle_AR:"ولاية الخرطوم",
aboutElKarTitle_EN:"Khartoum State",

aboutElKarEph_AR:"تدعم المنظمة المناطق المتاثرة جراء الكوارث الطبيعية مثل الفيضانات ",
aboutElKarEph_EN:"The City of Al Jillie north of Khartoum and village is affected by Seoul and flooding",

aboutElkarText_AR:"التي تتعاقب كل فترة علي اغلب مناطق السودان, ساهمت المنظمة في تخفيف اعباء المواطنين المتاثرين من السيول في منطقة الجيلي شمال الخرطوم , تأثرت مدينة الجيلي والقري المجاورة بالسيول والفيضانات ، كما تأثرت العديد من المدارس والمراكز الصحية والمنازل بالفيضانات ، وإنهار معظمها و تعرض بعضها لخطر السقوط.",
aboutElKarText_EN:" many schools, health centers, and homes were affected by the floods, and most of them collapsed or were in danger of falling.",



gratfulText_AR:"منظمة التواكي ممتنة لشركائها القيمين الذين يجلعون عملنا ممكنآ",
gratfulText_EN:"Al Twaki Organization is grateful for the valued partners who make our work possible.",


orgstructureEmphText_AR:"جوهر منظمةالتواكي للتنمية هو الجمعية العامة ",
orgstructureEmphText_EN:"The core of AL Twaki is the General Assembly .",

orgstructureText_AR:<div style={{direction:"rtl"}}>
وهي تتألف من أعضاء المنظمة. تنتخب الجمعية العامة أعضاء المجلس التنفيذي والمدير العام كل ثلاث سنوات و يمكن للأعضاء خدمة أكثر من فترة واحدة.

<div style={{marginTop:10}}>المجلس التنفيذي مسؤول عن تقديم تقرير إلى الجمعية العامة من خلال الآليات التالية:</div>
<div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}></span> ⦁	مجلس الأمناء. </div> 
<div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}></span>⦁ الاجتماع السنوي العام  .</div>
<div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}></span> ⦁	تقرير فصلي. .</div>
</div>,
orgstructureText_EN:<div>
It comprises of the organization members. The general assembly elects the members of executive board and general director each three years. The members can serve more than one term.

<div style={{marginTop:10}}>The Executive Board is accountable to report to the General Assembly through the following mechanisms:</div>
<div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}></span> ⦁	The Board of Trustees </div> 
<div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}></span>⦁	Annual General Meeting .</div>
<div> <span style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold"}}></span> ⦁	Quarterly Report.</div>
</div>,


whatWeDoMainText_AR:"في منطقة القضارف ، نركز مساعدتنا على أنشطة التنمية للسكان والمهاجرين من البلدان الحدودية. ",
whatWeDoMainText_EN:"At Al Gadarif Area we focus our assistance on development activities for the residents and the immigrants from border countries. ",

whatWeDoText_AR:"",
whatWeDoText_EN:"",

whatwedoareasMain_AR:"نحن نعمل ضمن سبعة محاور",
whatwedoareasMain_EN:"WE work within these areas:",

whatwedoareasSub_AR:<div style={{direction:'rtl'}}> 
<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}> 1) الحماية: 
</div>
الدعوة لحقوق المهاجرين ، ومبادرات حماية الطفل ، وتوفير الدعم العاجل للمهاجرين في مراكز الاحتجاز. أيضاًالمساعدة في الحماية الفردية على أساس الضعف والمساعدة القانونية وتسهيل عمليات العودة والإعادة إلى الوطن.
</div>


<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>2) المأوى والمواد الضرورية اليومية :
</div>
 توفير المأوى في حالات الطوارئ ، وإعادة تأهيل المساكن ، وتوزيع المواد الضرورية اليومية ، وتوفير أدوات الإعادة إلى الوطن.

</div>

<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>3) التنسيق والخدمات التشغيلية:

</div>
تنسيق وإدارة مخيمات اللاجئين والمشردين داخليا, وتنمية القدرات والتدريب ودعم المجتمع المحلي.
</div>


<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>4) برامج تطوير الدخل:
</div>


أنشطة لتطويرالاقتصاد وسبل العيش ، بما في ذلك التدريب على الأعمال التجارية وتطوير المشاريع الصغيرة والمتوسطة الحجم ، ومنح الأعمال التجارية ، والتدريب على المهارات الحياتية ، والتدريب المهني ، وتنمية المشاريع الجماعية وتيسيرها.

</div>


<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>5) الأمن الغذائي:

</div> 
 التدريب وتنمية القدرات في الزراعة والمدخلات الزراعية (مثل الآليات الزراعية والبذور).
التوعية بصحة الحيوان وزيادة الانتاج الحيواني من اللحوم والالبان .
</div>



<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>6) الصرف الصحي للمياه والنظافة (WASH):

</div> 

الوصول إلى خدمات المياه والصرف الصحي والنظافة الصحية المستدامة ، وزيادة الوعي المجتمعي.

</div>


<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>7) التعليم:

</div> 

البرامج المدرسية وتدريب المعلمين ودعمهم وتوفير المواد المدرسية وبناء أو إعادة تأهيل الهياكل المدرسية.

</div>




</div>,
whatwedoareasSub_EN:<div> 
<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>1) Protection: 
</div> Advocacy for the rights of Migrants, child protection initiatives ,providing urgent
 support to immigrants in the detention centers. Individual  protection assistance based on 
 vulnerability, legal aid, facilitation of return and repatriation processes.
</div>


<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>2) Shelter and Non-Food Items:
</div> Provision of emergency shelter, rehabilitation of housing, distribution of non-food items/services (NFIs) and provision of repatriation kits.
</div>

<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>3) Coordination and Operational Services:

</div> Economic recovery and livelihood activities including business training and SME development, business grants, life-skills training, vocational training, group enterprise development and facilitation.
5</div>


<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>4) Income Generation Programs
</div> Provision of emergency shelter, rehabilitation of housing, distribution of non-food items/services (NFIs) and provision of repatriation kits.
</div>


<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>5) Food Security:

</div> Training and capacity development in agriculture, agricultural inputs (e.g. tools and seeds), and agricultural grants.

</div>



<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>6) Water Sanitation and Hygiene (WASH):

</div> Access to sustainable WASH .services, raising community awareness.
</div>


<div> <div style={{color:GLSTYLE.colors.blue.s900,fontWeight:"bold",marginTop:10}}>7) Education:
</div> School programmes; teacher training and support, school materials provision and construction or rehabilitation of school structures.

</div>




</div>,


  };

  export  function GLTF(ar,en){

    if(localStorage.getItem("lang")==="AR"){
    return ar;
    }else if (localStorage.getItem("lang")==="EN"){
      return en;
    }else{
      return en ;
    }
  }
  



//   https://mohammadiaprint.com:5000
//   http://localhost:5000