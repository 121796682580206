import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {GLSTYLE, GLTRAN,GLTF,GLCON} from "../GLBL";
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
     
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
           {props.isvid?  <video width="100%"  controls>
  <source src={props.media} type="video/mp4"/>
 
 
</video>:<div> <img alt={""} src={GLCON.SERVER_URL+props.media[0].path} width={'100%'} /></div>}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {props.text}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
