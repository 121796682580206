

import React from 'react';
import AboutEl  from '../Comps/aboutEl';
import {GLTRAN,GLSTYLE,GLTF} from "../GLBL";
import illu from "../img/org.PNG";
import all from "../img/bg2.PNG";
import all2 from "../img/bg11.PNG";
import blob1 from "../img/blob (6).svg";
import blob2 from "../img/blob (3).svg";
import ContactUs from '../Comps/contact';
import SubHeader from '../Comps/subHeader';
import Footer from '../Comps/footer';
import blob3 from "../img/blob (5).svg";
import News from "../Comps/news";

class WhatWeDo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     newsData:[],
      };
  }


  componentDidMount() {
    this.props.pathfunc(window.location.href);



     }






  render() {
 
   // alert(this.props.navtag);
 

    return (
  
      <>
        
      <div style={{padding:0,margin:0,overflow:'hidden',width:'100%'}}>
    
    
  <SubHeader img={all} text={""} emphText={GLTF(GLTRAN.whatWeDoMainText_AR,GLTRAN.whatWeDoMainText_EN)} />


<div style={{position:"absolute",zIndex:-1,bottom:0,left:0}}>
<img  alt={""} src={blob1} width={200} />
</div>

<div style={{position:"absolute",zIndex:-1,top:300,right:20}}>
<img  alt={""} src={blob2} width={200} />
</div>




<div style={{position:"absolute",zIndex:-1,top:2200,left:20}}>
<img  alt={""} src={blob2} width={200} />
</div>




<AboutEl  showmore={0} key={2} variant={"split"}  dir={1} img={all2}
 title={GLTF(GLTRAN.whatWeDoTabText_AR,GLTRAN.whatWeDoTabText_EN)} 
 emphText={GLTF(GLTRAN.whatwedoareasMain_AR,GLTRAN.whatwedoareasMain_EN)} 
 text={GLTF(GLTRAN.whatwedoareasSub_AR,GLTRAN.whatwedoareasSub_EN)} />





<Footer/>
</div>


    </>

    );
  }
}

export default WhatWeDo;
 



   

 
  
 
  
   
