import React from "react";
import Menu from "./Comps/menu";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     currentPage:"",
     open:false,
    

      };
  }

 changeLang = ()=>{
if(localStorage.getItem("lang")==="EN"){
  localStorage.setItem("lang","AR")
}else if (localStorage.getItem("lang")==="AR"){
  localStorage.setItem("lang","EN")
}else{
  localStorage.setItem("lang","EN")
}

window.location.replace("/");
}


 handleDrawerOpen = () => {
  if(this.state.open==false){
    this.setState({
     open:true
    });
  }else{
    this.setState({
      open:false
     });
  }
};

locations=(path)=>{
 // window.location.replace("#")
 
  
  this.setState({
    currentPage: path.slice(path.lastIndexOf("/"),path.length),
    open:false
  });


  console.log(path.slice(path.lastIndexOf("/"),path.length));
}


render() {
  return (
   <Menu open={this.state.open} handleDrawerOpen={this.handleDrawerOpen} locations={this.locations} changeLang={this.changeLang} currentPage={this.state.currentPage} />
  );
}
}


export default App

