import React from 'react';
import {GLCON,GLSTYLE} from "../GLBL";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import {FaBars,FaTimes} from 'react-icons/fa';
import IconButton from '@material-ui/core/IconButton';


class PhotoGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     newsData:[],
     photos:[],
     zoom:"",
     open:false
      };
  }


  componentDidMount() {
    this.props.pathfunc(window.location.href);
    this.GetData();


     }


     handleOpen=()=>{
       if(this.state.open){
        this.setState({
          open:false, 
          });
       }else{
        this.setState({
          open:true, 
          });
       }
     }

     
  GetData = () => {
    fetch(GLCON.SERVER_URL+'/cockpit/api/collections/get/news', {
   
    })
    .then(res=>res.json())
    .then(res => {
    console.log(res.entries);
  

    this.extractphoto(res.entries);
    }

    
     
   
    );
  };

  extractphoto = (obs)=>{

let phs = [];
    
  
  obs.map((ob,index)=>{
 
    ob.imgs.map((obo,indexo)=>{
      console.log(obo.path)
      phs.push(obo.path);
    
      })
    
       
      
       })

     this.setState({
     photos:phs, 
     });


  }


  zoomFunc=(zph)=>{

    this.setState({
      zoom:zph, 
      open:true,
      });

  }



  render() {
 
   // alert(this.props.navtag);
 

    return (
  
      <>
    <div style={{display:'flex',flexWrap:'wrap',marginTop:120}}>
{

  
  this.state.photos.map((ob,index)=>{
 
   return <div onClick={()=>{this.zoomFunc(GLCON.SERVER_URL+ob)}} style={{margin:10,width:300,cursor:'pointer',boxShadow:GLSTYLE.box.raised,display:'inline-block'}}> <img width={"100%"} src={ GLCON.SERVER_URL+ob} /> </div>
       
      
       })

       

}

</div>          

<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{width:"100vw",height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}
        open={this.state.open}
       
        // onEscapeKeyDown={props.modalclose}
        onClose={()=>{this.handleOpen()}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
      <div style={{width:"90%",height:"90%",backgroundColor:"black",display:"flex",justifyContent:'center',alignItems:'center'}}>
    
    <img height={"100%"} src={this.state.zoom} />


    <div onClick={()=>{this.handleOpen()}} style={{padding:5,margin:10,backgroundColor:GLSTYLE.primary,borderRadius:200,position:'absolute',top:20,right:20}}>
     <IconButton style={{color:"white"}} >
               {<FaTimes/>} 
            
          </IconButton>
     </div>
      </div>
      </Modal>



    </>

    );
  }
}

export default PhotoGallery;
 



   

 
  
 
  
   
