

import React from 'react';
import AboutEl  from '../Comps/aboutEl';
import {GLTRAN,GLSTYLE,GLTF} from "../GLBL";
import all from "../img/bonoo.jpg";
import img from "../img/bg6.PNG";
import farm from "../img/bg11.PNG";
import illu from "../img/bg4.PNG";
import women from "../img/bg7.PNG";
import man from "../img/bg9.PNG";
import child from "../img/bg10.PNG";
import mission from "../img/bg4.PNG";
import blob1 from "../img/blob (6).svg";
import blob2 from "../img/blob (3).svg";
import ContactUs from '../Comps/contact';
import Footer from '../Comps/footer';
import blob3 from "../img/blob (5).svg";
import News from "../Comps/news";
import SubHeader from '../Comps/subHeader';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     newsData:[],
     id:"org"
      };
  }


  componentDidMount() {
    this.props.pathfunc(window.location.href);
this.scrl();
    




     }


     scrl = ()=>{
      setInterval(()=>{ 
        // let elmnt = document.getElementById(window.location.href.slice(window.location.href.lastIndexOf("#")+1,window.location.href.length));
        // elmnt.scrollIntoView();

        this.setState({ id: window.location.href.slice(window.location.href.lastIndexOf("#")+1,window.location.href.length) })
    // console.log(this.state.id)
      }, 1000);
     
     }






  render() {
 
   // alert(this.props.navtag);
 

    return (
  
      <>
        
      <div style={{padding:0,margin:0,overflow:'hidden',width:'100%'}}>
<div  >


<span  id="org" style={{display:this.state.id=="org"?"block":"none"}} >
  <SubHeader img={img} text={GLTF(GLTRAN.aboutElAboutUsMoreText_AR,GLTRAN.aboutElAboutUsMoreText_EN)} emphText={GLTF(GLTRAN.aboutElAboutUsEph_AR,GLTRAN.aboutElAboutUsEph_EN)} />
  </span>
 


</div>



 



<div style={{width:'100%'}}>
<span  id="org" style={{display:this.state.id=="vision"?"block":"none"}} >
<AboutEl  showmore={0} key={2} variant={"split"}  dir={1} img={mission}
 title={GLTF(GLTRAN.aboutElVisTitle_AR,GLTRAN.aboutElvisTitle_EN)} 
 emphText={GLTF(GLTRAN.aboutElvisEph_AR,GLTRAN.aboutElvisEph_EN)} 
 text={""} />
</span>

<span  id="org" style={{display:this.state.id=="mission"?"block":"none"}} >
<AboutEl   showmore={0} key={3} variant={"split"} dir={0} img={women}
 title={GLTF(GLTRAN.aboutElmisTitle_AR,GLTRAN.aboutElmisTitle_EN)} 
 emphText={GLTF(GLTRAN.aboutElmisEph_AR,GLTRAN.aboutElmisEph_EN)}
  text={GLTF(GLTRAN.aboutElmisMoreText_AR,GLTRAN.aboutElmisMoreText_EN)} />
</span>


<span  id="org" style={{display:this.state.id=="values"?"block":"none"}} >
<AboutEl  showmore={0} key={4} variant={"split"} dir={1} img={child}
 title={GLTF(GLTRAN.aboutElvalTitle_AR,GLTRAN.aboutElvalTitle_EN)}
  emphText={GLTF(GLTRAN.aboutElvalEph_AR,GLTRAN.aboutElvalEph_EN)}
   text={GLTF(GLTRAN.aboutElvalMoreText_AR,GLTRAN.aboutElvalMoreText_EN)} />
</span>


<span  id="org" style={{display:this.state.id=="objective"?"block":"none"}} >
<AboutEl    showmore={0} key={4} variant={"split"} dir={0} img={farm}
 title={GLTF(GLTRAN.aboutElobjTitle_AR,GLTRAN.aboutElobjTitle_EN)}
  emphText={GLTF(GLTRAN.aboutElobjEph_AR,GLTRAN.aboutElobjEph_EN)}
   text={GLTF(GLTRAN.aboutElobjText_AR,GLTRAN.aboutElobjText_EN)} />
</span>

<span  id="org" style={{display:this.state.id=="plan"?"block":"none"}} >
<AboutEl  showmore={0} key={5} variant={"center"} dir={1} img={all}
 title={GLTF(GLTRAN.aboutElplanTitle_AR,GLTRAN.aboutElplanTitle_EN)}
  emphText={GLTF(GLTRAN.aboutElplanEph_AR,GLTRAN.aboutElplanEph_EN)}
   text={GLTF(GLTRAN.aboutElplanText_AR,GLTRAN.aboutElplanText_EN)} />
   </span>      

</div>







<Footer/>
</div>


    </>

    );
  }
}

export default Home;
 



   

 
  
 
  
   
