

import React from 'react';
import AboutEl  from '../Comps/aboutEl';
import {GLTRAN,GLSTYLE,GLTF,GLCON} from "../GLBL";
import illu from "../img/happy-illustration.svg";
import all from "../img/bonoo.jpg";
import blob1 from "../img/blob (6).svg";
import blob2 from "../img/blob (3).svg";
import Partners from './partners';
import ContactUs from '../Comps/contact';
import Footer from '../Comps/footer';
import blob3 from "../img/blob (5).svg";
import News from "../Comps/news";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class NewsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     newsData:[],
      };
  }


 


  componentDidMount() {
    this.props.pathfunc(window.location.href);

   this.GetData();

     }


  GetData = () => {
    fetch(GLCON.SERVER_URL+'/cockpit/api/collections/get/news', {
   
    })
    .then(res=>res.json())
    .then(res => {
    console.log(res.entries);
      this.setState({
     newsData:res.entries.reverse(), 
      });
    }
     
   
    );
  };






  render() {
 
   // alert(this.props.navtag);
 

    return (
  
      <>
  <div style={{padding:10,paddingTop:130,display:'flex',justifyContent:'center',alignItems:"center",flexWrap:'wrap'}}>     
     
{this.state.newsData.map((ob,index)=>{
 
return <News key={index} ob={ob} lang={localStorage.getItem("lang")}  content={localStorage.getItem("lang")==="EN"?ob.content_EN:ob.content_AR} date={ob.date} imgs={ob.imgs}  /> 
  
  })}




</div>
<Footer/>

    </>

    );
  }
}

export default NewsList;
 



   

 
  
 
  
   
