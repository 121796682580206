import React from "react";
import {GLSTYLE, GLTRAN,GLTF,GLCON} from "../GLBL";
import illu from "../img/contact-us-illustration.svg";
import AboutEl from "../Comps/aboutEl";
import Footer from "../Comps/footer";
import all from "../img/bonoo.jpg";
import blob2 from "../img/blob (3).svg";
import ContactUs from "./programme";
import sp1 from "../img/sp1.PNG";
import sp2 from "../img/sp2.PNG";
import sp3 from "../img/sp3.PNG";
import sp4 from "../img/sp4.PNG";
import sp5 from "../img/sp5.PNG";
import sp6 from "../img/sp6.PNG";
import sp7 from "../img/sp7.PNG";
import sp8 from "../img/sp8.PNG";
import sp9 from "../img/sp9.PNG";
import sp10 from "../img/sp10.PNG";


import {

  
  Link
} from "react-router-dom";


class Partners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
   
      };
  }


  // console.log(document.getElementById("aa").offsetTop);

    componentDidMount() {
    this.props.pathfunc(window.location.href);
    


      }

   
 render(){
      return (
<>
<div style={{paddingTop:this.props.mar?200:0}}>

<div style={{fontSize:20,color:GLSTYLE.colors.blue.s700,fontWeight:'bold',textAlign:'center',padding:10}}>
{GLTF(GLTRAN.gratfulText_AR,GLTRAN.gratfulText_EN)}
</div>

<div style={{padding:40,display:'flex',justifyContent:'space-around',alignItems:"center",flexWrap:'wrap'}}>
<div style={{width:100,height:100,margin:10}} >
  <img src={sp10} width={'100%'} />
</div>

<div style={{width:100,height:100,margin:10}} >
  <img src={sp9} width={'100%'} />
</div>
<div style={{width:100,height:100,margin:10}} >
  <img src={sp8} width={'100%'} />
</div>
<div style={{width:100,height:100,margin:10}} >
  <img src={sp7} width={'100%'} />
</div>
<div style={{width:100,height:100,margin:10}} >
  <img src={sp6} width={'100%'} />
</div>
<div style={{width:100,height:100,margin:10}} >
  <img src={sp5} width={'100%'} />
</div>
<div style={{width:100,height:100,margin:10}} >
  <img src={sp4} width={'100%'} />
</div>
<div style={{width:100,height:100,margin:10}} >
  <img src={sp3} width={'100%'} />
</div>
<div style={{width:100,height:100,margin:10}} >
  <img src={sp2} width={'100%'} />
</div>
<div style={{width:100,height:100,margin:10}} >
  <img src={sp1} width={'100%'} />
</div>



</div>
</div>
</>
    
      );
    

      }
  

 }


 export default Partners;


