import React from "react";
import {GLSTYLE, GLTRAN,GLTF,GLVARS} from "../GLBL";
import blob2 from "../img/blob (3).svg";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {

  
  Link
} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  heroDiv:{
    display:'flex',
    //flexDirection:AboutEl.props.dir?'row-reverse':'row', 
    justifyContent:'space-between',alignItems:'center',
    padding:100,
    paddingBottom:50,
       [theme.breakpoints.down('sm')]: {
        padding:10,
        marginBottom:30,
       },
      },

      planText:{
        height:"100%",width:'60%',
        [theme.breakpoints.down('sm')]: {
          padding:10,
         width:"100%"
         },
      },


      titleText:{
        color:GLSTYLE.colors.blue.s900,fontSize:40,fontWeight:'bold',marginTop:10,textAlign:GLVARS.lang?"right":"left",
        [theme.breakpoints.down('sm')]: {
          fontSize:30,
         },
      },
 
}));


export default function AboutEl(props) {
  const classes = useStyles();
  const theme = useTheme();

  // console.log(document.getElementById("aa").offsetTop);

  switch(props.variant){

    case "split":

      return (

        <div className={classes.heroDiv} >  
<Grid  style={{flexDirection:props.dir?'row-reverse':'row',display:'flex',alignItems:"center",justifyContent:'center'}} container spacing={0}>

<Grid  style={{padding:20 }} item sm={6} xs={12}>
<div style={{height:"100%",width:'100%'}}>
    <div style={{color:GLSTYLE.colors.primary.s600,fontSize:20,fontWeight:'bold',textAlign:GLVARS.lang?"right":"left"}}>{props.title}</div>
    <div className={classes.titleText}>
    {props.emphText}
    </div>
    
    <div style={{color:GLSTYLE.colors.gray.s800,fontSize:20,marginTop:10,textAlign:GLVARS.lang?"right":"left"}}>
    {props.text}
    </div>
    
    
    <div style={{display:props.showmore?'flex':"none",justifyContent:GLVARS.lang?'left':'right',alignItems:'center',marginTop:20}}>
    <div style={{color:'white',padding:10,marginRight:10,borderRadius:5,backgroundColor:GLSTYLE.primary}}>
      
    <Link style={{color:"white",textDecoration:'none'}} to={props.link}>{GLTF(GLTRAN.ShowMoreText_AR,GLTRAN.ShowMoreText_EN)}</Link>
        
    
    </div>
    </div>
    
    
    
    </div>
</Grid>

<Grid  style={{marginTop:20}} item sm={6} xs={12}>
<div style={{height:'100%',width:"100%",boxShadow:GLSTYLE.box.none,borderRadius:5}}>
    <img alt={""} style={{borderRadius:5}} src={props.img} width={'100%'} />
    </div>
  </Grid>
</Grid>
  
    
   
    
        </div>
    
    
      );
    

    break;


    case "center":

      return (

        <div className={classes.heroDiv}>
    
    <div className={classes.planText}>
      
    <div style={{color:GLSTYLE.colors.primary.s600,fontSize:20,fontWeight:'bold',textAlign:GLVARS.lang?"right":"left"}}>{props.title}</div>
    <div className={classes.titleText}>
    {props.emphText}
    </div>
    
    <div style={{color:GLSTYLE.colors.gray.s800,fontSize:20,marginTop:10,textAlign:GLVARS.lang?"right":"left"}}>
    {props.text}
    </div>
    
    
    <div  style={{display:props.showmore?'flex':"none",justifyContent:'center',alignItems:'center',marginTop:20}}>
    <div style={{color:'white',padding:10,borderRadius:5,backgroundColor:GLSTYLE.primary}}>
    {GLTF(GLTRAN.ShowMoreText_AR,GLTRAN.ShowMoreText_EN)}
    </div>
    </div>
    
    
    
    </div>
    
    
        </div>
    
    
      );
    

    break;




    default :
 return(
   <div></div>
 );
    break;


  }

 }

